@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../global";
@import "global";

// Variables
// @import 'variables';

// Bootstrap
// @import '~bootstrap/scss/bootstrap';
